<template>
  <div class="card-white">
    <div class="flex w-full align-items-start flex-column">
      <div class="flex w-full align-items-center justify-content-between">
        <h1 class="h1-gray">Vídeo de apresentação</h1>
        <img src="../../assets/icons/edit.png" alt="" />
      </div>

      <img class="m-auto" src="../../assets/images/video.png" alt="" style="max-width: 100%;margin-top: 8px !important;" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.limit {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;
}
.card-white {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  background: white;
  border-radius: 8px;
  h1 {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #2d313d;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 24px; */
  }
  .profile-span {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 190.94%;
    color: #bdbdbd;
  }
  .content {
    margin-left: 8px;
  }
  .h1-gray {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #bdbdbd;
  }
}
.color-gray {
  color: #828282;
}
.color-orange {
  color: #ff6a33;
}
</style>