<template>
  <div class="card-white">
    <div class="flex w-full align-items-start flex-column">
      <div class="flex w-full align-items-center justify-content-between">
        <h1 class="h1-gray">Local de atendimento</h1>
        <img src="../../assets/icons/edit.png" alt="" />
      </div>

      <div class="flex align-items-center" style="margin-top: 8px;">
        <img class="m-auto" src="../../assets/images/sodre.png" alt="" />
        <div class="flex flex-column" style="margin-left: 8px;">
          <p class="font-bold">Clínica Sodré</p>
          <p>
            Av. Juscelino Kubitschek, 1423 - Centro - Florianópolis - Santa
            Catarina 88.023-654
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.limit {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;
}
.card-white {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  background: white;
  border-radius: 8px;
  h1 {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #2d313d;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
  .profile-span {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 190.94%;
    color: #bdbdbd;
  }
  .content {
    margin-left: 8px;
  }
  .h1-gray {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #bdbdbd;
  }
}
.color-gray {
  color: #828282;
}
.color-orange {
  color: #ff6a33;
}
</style>