<template>
  <div class="p-3 pt-0">
    <div class="limit p-3">
      <div class="flex align-items-center">
        <span class="title-color fs-10 text-bold">Perfil profissional</span>
        <p class="btn-header">Ver como paciente</p>
      </div>

      <Profile />
      <Register />
      <Video />
      <Local />
      <TreatedDiseases />
      <Experiences />
      <Awards />
      <Language />
      <Publications />
      <AcademicEducation />
      <Certificates />
    </div>
  </div>
</template>

<script>
import Profile from "./Profile.vue"
import Register from "./Register.vue"
import Video from "./Video.vue"
import Local from "./Local.vue"
import TreatedDiseases from "./TreatedDiseases.vue"
import Experiences from "./Experiences.vue"
import Awards from "./Awards.vue"
import Language from "./Language.vue"
import Publications from "./Publications.vue"
import AcademicEducation from "./AcademicEducation.vue"
import Certificates from "./Certificates.vue"

export default {
  components: { 
    Profile,
    Register,
    Video,
    Local,
    TreatedDiseases,
    Experiences,
    Awards,
    Language,
    Publications,
    AcademicEducation,
    Certificates,
  },

};
</script>

<style lang="scss" scoped>
.limit {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;
}
.btn-header {
  background: white;
  box-shadow: 0px 0px 16px rgba(61, 71, 102, 0.08);
  backdrop-filter: blur(8px);
  border-radius: 4px;
  color: #ff6a33;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  padding: 4px 8px;
  margin-left: 8px;
}
</style>