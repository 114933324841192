<template>
  <div class="card-white">
    <div class="flex w-full align-items-start flex-column">
      <h1 class="h1-gray">Publicações</h1>

      <div class="list-cards">
        <div
          class="item"
          v-for:="(item, index) in items"
          :class="index === items.length - 1 && 'no-border-bottom'"
        >
          <div class="flex w-full align-items-center justify-content-between">
            <h1>{{ item.name }}</h1>
            <img
              class="pointer"
              src="../../assets/icons/edit.png"
              alt=""
              @click="editItem(item)"
              v-tooltip.top="'Editar ' + item.name + '.'"
            />
          </div>
          <p style="padding-right: 12px; margin-top: 12px;">{{ item.description }}</p>
          <img class="mt-16" src="../../assets/icons/globe.png" alt="" />
        </div>
        <div class="flex justify-content-end w-full mt-16 pb-0">
          <p class="color-orange">+ Adicionar publicação</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    const items = ref([
      {
        name: "Medicina do trabalho em tempos de COVID-19",
        description:
          "Em 11 de março de 2020, a Organização Mundial da Saúde (OMS) declarou como pandemia uma doença  infectocontagiosa  caracterizada  por  síndrome  respiratória  aguda  severa  (severe  acute  respiratory  syndrome – SARS) causada por um tipo novo de coronavírus, denominado SARS-CoV-2. Próximo a esta data, o Ministério da Saúde do Brasil informou a ocorrência de casos autóctones desta virose alertando para o início da transmissão comunitária ou sustentada da doença.",
      },
      {
        name: "Lúpus Eritematoso Sistêmico",
        description:
          "O  Lúpus  Eritematoso  Sistêmico  (LES)  é  uma  doença  inflamatória  crônica  de  origem  autoimune  que  apresenta  manifestações  clínicas  variáveis,  sendo  progressiva  e  potencialmente  fatal,  se  não  tratado.  Os  tratamentos  padrões  incluem antimaláricos, corticosteroides (CS) e imunossupressores. No  entanto,  apesar  do  melhor  entendimento  do  processo  da  doença, ainda há uma necessidade significativa e não atendida de novo tratamento devido ao alto risco.",
      },
    ]);

    const editItem = (item) => {
      // items.value = items.value.filter((it) => it !== item);
    };
    return { items, editItem };
  },
};
</script>

<style lang="scss" scoped>
.limit {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;
}
.card-white {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  background: white;
  border-radius: 8px;
  h1 {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #2d313d;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 24px; */
  }
  .profile-span {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 190.94%;
    color: #bdbdbd;
  }
  .content {
    margin-left: 8px;
  }
  .h1-gray {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #bdbdbd;
  }
  .list-cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    .item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 0;
      border-bottom: 1px solid #f2f2f2;
    }
    .no-border-bottom {
      border-bottom: 1px solid white !important;
    }
  }
}
.mt-16 {
  margin-top: 16px;
}
.color-gray {
  color: #828282;
}
.color-orange {
  color: #ff6a33;
}
.pointer {
  cursor: pointer;
}
</style>