<template>
  <div class="card-white">
    <div class="flex w-full align-items-start flex-column">
      <h1 class="h1-gray">Premiações</h1>

      <div class="list-cards">
        <div
          class="item"
          v-for:="(item, index) in items"
          :class="index === items.length - 1 && 'no-border-bottom'"
        >
          <div class="flex w-full align-items-center justify-content-between">
            <h1>{{ item.name }}</h1>
            <img
              class="pointer"
              src="../../assets/icons/edit.png"
              alt=""
              @click="editItem(item)"
              v-tooltip.top="'Editar ' + item.name + '.'"
            />
          </div>
          <h2>{{ item.local }}</h2>
        </div>
      </div>
      <div class="flex justify-content-between align-items-center w-full pb-0">
        <img src="../../assets/icons/globe.png" alt="" />

        <p class="color-orange">+ Adicionar premiação</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    const items = ref([
      {
        name: "CMS Stage 1 EHR (2013)",
        local: "7500 Security Boulevard, Baltimore, MD 21244",
      },
      {
        name: "AAD Fellow (2016)",
        local: "7500 Security Boulevard, Baltimore, MD 21244",
      },
    ]);

    const editItem = (item) => {
      // items.value = items.value.filter((it) => it !== item);
    };
    return { items, editItem };
  },
};
</script>

<style lang="scss" scoped>
.limit {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;
}
.card-white {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  background: white;
  border-radius: 8px;
  h1, h2 {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #2D313D;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 24px; */
  }
  .profile-span {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 190.94%;
    color: #bdbdbd;
  }
  .content {
    margin-left: 8px;
  }
  .h1-gray {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #bdbdbd;
  }
  .list-cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 0 16px;
    .item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 0;
      border-bottom: 1px solid #f2f2f2;
    }
    .no-border-bottom {
      border-bottom: 1px solid white !important;
    }
  }
}
.mt-16 {
  margin-top: 16px;
}
.color-gray {
  color: #828282;
}
.color-orange {
  color: #ff6a33;
}
.pointer {
  cursor: pointer;
}
</style>