<template>
  <div class="card-white">
    <div class="flex w-full align-items-start flex-column">
      <h1 class="h1-gray">Idioma</h1>

      <div class="list-cards">
        <div class="item" v-for:="item in items">
          <p>{{ item }}</p>
          <img
            class="pointer"
            src="../../assets/icons/close_application.png"
            alt=""
            @click="removeItem(item)"
            v-tooltip.top="'Remover <i>' + item +'</i>.'"
          />
        </div>
      </div>
      <img class="mt-16" src="../../assets/icons/globe.png" alt="">
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    const items = ref([
      "Inglês",
      "Alemão",
      "Espanhol",
      "Francês",
      "Português",
    ]);

    const removeItem = (item) => {
      items.value = items.value.filter((it) => it !== item);
    };
    return { items, removeItem };
  },
};
</script>

<style lang="scss" scoped>
.limit {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;
}
.card-white {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  background: white;
  border-radius: 8px;
  h1 {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #2d313d;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 24px; */
  }
  .profile-span {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 190.94%;
    color: #bdbdbd;
  }
  .content {
    margin-left: 8px;
  }
  .h1-gray {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #bdbdbd;
  }
  .list-cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 16px;
    gap: 12px;
    padding: 12px;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border: 1px solid #f2f2f2;
      border-radius: 16px;
      padding: 9px 16px;
    }
  }
}
.mt-16{
  margin-top: 16px;
}
.color-gray {
  color: #828282;
}
.color-orange {
  color: #ff6a33;
}
.pointer {
  cursor: pointer;
}
</style>