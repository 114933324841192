<template>
  <div class="card-white">
    <div class="flex w-full align-items-start flex-column">
      <h1 class="h1-gray">Certificados</h1>

      <div class="list-cards">
        <div class="item">
          <img src="../../assets/images/certificado.png" alt="">
          <div class="flex justify-content-center align-items-center mt-1">
            <img src="../../assets/icons/excluir.png" alt="">
            <p class="color-orange ml-1">Excluir</p>
          </div>
        </div>
      </div>
      <div
        class="
          flex
          justify-content-between
          align-items-center
          w-full
          pb-0
        "
      >
        <img src="../../assets/icons/globe.png" alt="" />

        <p class="color-orange">+ Adicionar certificado</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {

    const editItem = (item) => {
      // items.value = items.value.filter((it) => it !== item);
    };
    return { editItem };
  },
};
</script>

<style lang="scss" scoped>
.limit {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;
}
.card-white {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  background: white;
  border-radius: 8px;
  h1 {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #2d313d;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 24px; */
  }
  .profile-span {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 190.94%;
    color: #bdbdbd;
  }
  .content {
    margin-left: 8px;
  }
  .h1-gray {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #bdbdbd;
  }
  .list-cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    .item {
      display: flex;
      justify-content: center;
      width: max-content;
      flex-direction: column;
      align-items: center;
      padding: 16px 0;
    }
  }
}
.mt-16 {
  margin-top: 16px;
}
.color-gray {
  color: #828282;
}
.color-orange {
  color: #ff6a33;
}
.pointer {
  cursor: pointer;
}
</style>