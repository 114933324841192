<template>
  <div class="card-white">
    <img src="../../assets/images/medico.png" alt="" />
    <div class="content">
      <div class="flex w-full align-items-center justify-content-between">
        <h1>Dr. Carlos Sodré</h1>
        <img src="../../assets/icons/edit.png" alt="" />
      </div>

      <p class="color-gray mt6">
        Masculino
        <span class="profile-span"
          >O gênero é apenas para tratativa no sistema</span
        >
      </p>
      <p class="">
        Especialista em Cirurgia Refrativa a Laser, Cirurgia de Catarata e
        Implantes de Lente Intraocular. 48 anos de idade e vasta experiência na
        área médica - 20 anos de profissão e atuando há mais de 12 anos em
        Florianópolis/SC. Atendimento personalizado aos nossos pacientes,
        garantindo conforto, segurança e agilidade no tratamento.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.limit {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;
}
.card-white {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  background: white;
  border-radius: 8px;
  h1 {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #2d313d;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding-right: 12px;
  }
  .profile-span {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 190.94%;
    color: #bdbdbd;
    margin-left: 8px;
  }
  .content {
    margin-left: 16px;
  }
  .h1-gray {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #bdbdbd;
  }
  .mt6{
    margin-top: 6px;
  }
}
.color-gray {
  color: #828282;
}
.color-orange {
  color: #ff6a33;
}
</style>