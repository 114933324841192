<template>
  <div class="card-white">
    <div class="flex w-full align-items-start flex-column">
      <h1 class="h1-gray">Experiências</h1>

      <div class="list-cards">
        <div class="item" v-for:="(item, index) in items" :class="index === items.length - 1 && 'no-border-bottom'">
          <div class="flex w-full align-items-center justify-content-between">
            <h1>{{ item.name }}</h1>
            <img
            class="pointer"
            src="../../assets/icons/edit.png"
            alt=""
            @click="editItem(item)"
            v-tooltip.top="'Editar ' + item.name + '.'"
          />
          </div>
          <p style="margin-top: 8px;">{{item.local}}</p>
          <p>{{item.time}}</p>
        </div>
      </div>
      <div
        class="
          flex
          justify-content-between
          align-items-center
          w-full
          pb-0
        "
      >
        <img src="../../assets/icons/globe.png" alt="" />

        <p class="color-orange">+ Adicionar experiência</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    const items = ref([
      {
        name: "Clínica Sodré",
        local: "Florianópolis - SC, Brasil",
        time: "Desde 2009 - 12 anos",
      },
      {
        name: "Hospital das Clínicas",
        local: "Londrina - PR, Brasil",
        time: "De 2008 à 2009 - 1 ano e 11 meses",
      },
      {
        name: "USP Faculdade de Medicina",
        local: "São Paulo - SP, Brasil",
        time: "1998 - 2000",
      },
    ]);

    const editItem = (item) => {
      // items.value = items.value.filter((it) => it !== item);
    };
    return { items, editItem };
  },
};
</script>

<style lang="scss" scoped>
.limit {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;
}
.card-white {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  background: white;
  border-radius: 8px;
  h1 {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #2d313d;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 24px; */
  }
  .profile-span {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 190.94%;
    color: #bdbdbd;
  }
  .content {
    margin-left: 8px;
  }
  .h1-gray {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #bdbdbd;
  }
  .list-cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 0 16px;
    .item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 0;
      border-bottom: 1px solid #F2F2F2;
    }
  }
  .no-border-bottom{
    border-bottom: 1px solid white !important;
  }
}
.mt-16 {
  margin-top: 16px;
}
.color-gray {
  color: #828282;
}
.color-orange {
  color: #ff6a33;
}
.pointer {
  cursor: pointer;
}
</style>